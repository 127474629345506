import { createApp, h } from 'vue'
import { convertDataSet } from './helper'
import createNewsletter from '../components/newsletter/createNewsletter.vue'


if (document.getElementById("createNewsletter")) {
    const app5 = createApp({
        render() {
          return h(createNewsletter, convertDataSet('createNewsletter'))
        }
    })
    
    app5.mount('#createNewsletter')
  }