<template>
    <div>
        <el-form :model="newsletterForm" ref="newsletterFormRef" label-width="120px" label-position="top"
            @submit.prevent="submitForm(newsletterFormRef)">
            <el-form-item label="Introductie" prop="introduction" required>
                <el-input v-model="newsletterForm.introduction" type="textarea" />
            </el-form-item>

            <el-form-item label="Bible Quote Image URL" prop="bible_quote_image_url">
                <el-input v-model="newsletterForm.bible_quote_image_url" type="url" />
            </el-form-item>

            <el-form-item label="News Items">
                <div v-for="(item, index) in newsletterForm.news_items" :key="index">
                    <el-form-item label="Title" :prop="'news_items.' + index + '.title'">
                        <el-input v-model="item.title"></el-input>
                    </el-form-item>
                    <el-form-item label="Body" :prop="'news_items.' + index + '.body'">
                        <el-input v-model="item.body" type="textarea" />
                    </el-form-item>
                    <el-form-item label="Image">
                        <el-input type="file" v-model="item.image_url" accept="image/*" />
                    </el-form-item>
                    <el-form-item label="Image Link" :prop="'news_items.' + index + '.image_link'">
                        <el-input v-model="item.image_link" type="url" />
                    </el-form-item>
                    <el-button type="danger" @click="deleteNewsItem(index)">Delete</el-button>
                </div>
                <el-button type="primary" @click="addNewsItem">Add News Item</el-button>
            </el-form-item>

        </el-form>

        <el-button type="primary" @click="submitForm(newsletterFormRef)">Versturen</el-button>
    </div>
</template>

<script setup lang="ts">
import { ref, reactive } from 'vue'
import { ElNotification } from 'element-plus'
import type { FormInstance } from 'element-plus'
import http from "../../libs/axios"

interface Newsletter {
    introduction: string,
    bible_quote_image_url?: string,
    news_items: NewsItem[]
}

interface NewsItem {
    title?: string,
    body?: string,
    image_url?: string,
    image_link?: string
}

const newsletterFormRef = ref<FormInstance>()
const newsletterForm: Newsletter = reactive({
    introduction: '',
    bible_quote_image_url: '',
    news_items: []
})

import axios from 'axios'

const submitForm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return

    await formEl.validate((valid, fields) => {
        if (valid) {
            axios.post('/newsletter', newsletterForm, { responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'newsletter.html')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    savedSuccesNotification()
                })
                .catch(() => { return false })
        } else {
            return false
        }
    });
}

const addNewsItem = () => {
    newsletterForm.news_items.push({
        body: '',
        image_url: '',
        image_link: ''
    })
}

const deleteNewsItem = (index: number) => {
    newsletterForm.news_items.splice(index, 1)
}

function savedSuccesNotification() {
    ElNotification({
        title: 'Verstuurd',
        message: 'Nieuwsbrief succesvol aangemaakt!',
        type: 'success'
    })
}
</script>